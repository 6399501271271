import React from 'react';
import { useTranslation } from 'react-i18next';
import useAuth from '../../data/hook/useAuth';
import { isAdmin, isTeacher } from '../../functions/auth';
import { Tooltip } from '../common/dataDisplay/Tooltip';
import { AvatarBadge } from '../common/dataDisplay/AvatarBadge';
import { RewardsView } from '../common/dataDisplay/RewardsView';
import ConditionalRenderer from '../common/ConditionalRenderer';
import ComponentGuard from '../common/ComponentGuard';
import { UserTypeEnum } from '@/models/User';
import SuggestionsCard from '../common/SuggestionsCard';

export default function Header() {
  const path = window.location.pathname;
  const separator = path.split('/');
  const rootUrl = '/' + separator[1];
  const { user } = useAuth();

  const { t } = useTranslation('translation', { keyPrefix: 'avatar' });
  const blockedPaths = [
    '/admin',
    '/teacher',
    '/profile',
    '/projects',
    '/class-progress',
    '/classReport',
  ];

  function hasToRenderUserAvatar() {
    if (blockedPaths.includes(rootUrl)) {
      return false;
    } else if (
      (rootUrl !== path && rootUrl !== '/courses') ||
      (rootUrl === '/courses' &&
        (isAdmin(user?.userType) || isTeacher(user?.userType)))
    ) {
      return false;
    } else {
      return true;
    }
  }

  return (
    <div className="absolute right-8 hidden lg:flex z-10 top-3">
      {!hasToRenderUserAvatar() ? (
        <React.Fragment />
      ) : (
        <div className="flex gap-3 items-start">
          <ComponentGuard
            roles={[
              UserTypeEnum.TEACHER,
              UserTypeEnum.SUPER_ADMIN,
              UserTypeEnum.UNIT_ADMIN,
            ]}
          >
            <SuggestionsCard />
          </ComponentGuard>
          <RewardsView size={rootUrl === '/courses' ? 'small' : 'regular'} />
          <Tooltip text={t('profile')} color="accent">
            <ConditionalRenderer condition={user}>
              <AvatarBadge
                testId="profileAvatar"
                size={rootUrl === '/courses' ? '7' : '14'}
                user={user}
              />
            </ConditionalRenderer>
          </Tooltip>
        </div>
      )}
    </div>
  );
}
