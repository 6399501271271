import useStudentContext from '@/data/hook/student';
import useAuth from '@/data/hook/useAuth';
import { isStaff, isStudent } from '@/functions/auth';
import i18n from '@/i18n';
import { EnrollmentStatusEnum } from '@/models/Enrollment';
import { UserTypeEnum } from '@/models/User';
import { ROUTES } from '@/utils/routes';
import {
  AcademicCapIcon,
  AnnotationIcon,
  BellIcon,
  BookOpenIcon,
  CalendarIcon,
  CogIcon,
  CollectionIcon,
  IdentificationIcon,
  LibraryIcon,
  ViewGridIcon,
} from '@heroicons/react/outline';

export interface Option {
  testId: string;
  title: string;
  onClick?: (params?: any) => void;
  icon: JSX.Element;
  url: string;
  roles: UserTypeEnum[] | 'ALL';
  renderCondition?: {
    hasCondition: boolean;
    condition?: boolean;
  };
}

const defaultWitdh = 'h-5 w-5';

export default function useOptions() {
  const { user } = useAuth();

  const { currentProgress } = useStudentContext();

  const enrollmentStatus = [
    EnrollmentStatusEnum.ACTIVE,
    EnrollmentStatusEnum.CONCLUDED,
    EnrollmentStatusEnum.CANCELED,
  ];

  const coursesCondition =
    !!user &&
    (isStaff(user.userType) ||
      (isStudent(user.userType) &&
        currentProgress &&
        enrollmentStatus.includes(currentProgress?.enrollmentStatus)));

  const options: Option[] = [
    {
      testId: 'sidebarHome',
      title: i18n.t('sideMenu.home'),
      icon: <ViewGridIcon className={defaultWitdh} />,
      roles: 'ALL',
      url: '/',
      renderCondition: {
        hasCondition: false,
      },
    },
    {
      testId: 'sidebarCourses',
      title: i18n.t('sideMenu.courses'),
      icon: <AcademicCapIcon className={defaultWitdh} />,
      roles: 'ALL',
      url: '/courses',
      renderCondition: {
        hasCondition: true,
        condition: coursesCondition,
      },
    },
    {
      testId: 'sidebarProjects',
      title: i18n.t('sideMenu.projects'),
      icon: <CollectionIcon className={defaultWitdh} />,
      roles: 'ALL',
      url: '/projects',
      renderCondition: {
        hasCondition: false,
      },
    },
    {
      testId: 'sidebarClassroom',
      title: i18n.t('sideMenu.classroom'),
      icon: <BookOpenIcon className={defaultWitdh} />,
      roles: [UserTypeEnum.STUDENT],
      url: '/classroom',
      renderCondition: {
        hasCondition: true,
        condition: false,
      },
    },
    {
      testId: 'sidebarFeedback',
      title: i18n.t('sideMenu.feedback'),
      icon: <AnnotationIcon className={defaultWitdh} />,
      roles: [
        UserTypeEnum.TEACHER,
        UserTypeEnum.UNIT_ADMIN,
        UserTypeEnum.SUPER_ADMIN,
      ],
      url: '/student-lesson-feedback',
      renderCondition: {
        hasCondition: false,
      },
    },
    {
      testId: 'sidebarCalendar',
      title: i18n.t('sideMenu.calendar'),
      icon: <CalendarIcon className={defaultWitdh} />,
      roles: 'ALL',
      url: '/calendar',
      renderCondition: {
        hasCondition: true,
        condition: !isStudent(user?.userType),
      },
    },
    {
      testId: 'sidebarNotifications',
      title: i18n.t('sideMenu.notifications'),
      icon: <BellIcon className={defaultWitdh} />,
      roles: [
        UserTypeEnum.SUPER_ADMIN,
        UserTypeEnum.UNIT_ADMIN,
        UserTypeEnum.TEACHER,
      ],
      url: ROUTES.NOTIFICATIONS.BASE,
    },
    {
      testId: 'sidebarHowItWorks',
      title: i18n.t('sideMenu.howWorks'),
      icon: <LibraryIcon className={defaultWitdh} />,
      roles: [UserTypeEnum.PARENT],
      url: '/como-funciona',
    },
    {
      testId: 'sidebarAdmin',
      title: i18n.t('sideMenu.admin'),
      icon: <IdentificationIcon className={defaultWitdh} />,
      roles: [UserTypeEnum.SUPER_ADMIN, UserTypeEnum.UNIT_ADMIN],
      url: '/admin',
    },
    {
      testId: 'sidebarTeacher',
      title: i18n.t('sideMenu.teacher'),
      icon: <IdentificationIcon className={defaultWitdh} />,
      roles: [UserTypeEnum.TEACHER],
      url: '/teacher',
    },
    {
      testId: 'sidebarConfig',
      title: i18n.t('sideMenu.config'),
      icon: <CogIcon className={defaultWitdh} />,
      roles: 'ALL',
      url: '/account',
    },
  ];
  return options;
}
